import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@ui/Button';
import { getUserByUsernameSlug, updateUsername } from '@util/firestore/users';
import { logError } from '@util/logError';
import { FirebaseError } from 'firebase/app';
import { useRouter, useSearchParams } from 'next/navigation';
import { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { z } from 'zod';
import { usernameSchema } from 'models/user';
import { motion } from 'framer-motion';
import { AUTH } from '@util/firebase';
import AuthFormInput from '@c/forms/components/AuthFormInput';
import { useAuth } from 'context/AuthContext';

const UsernameForm = ({
  uid,
  isSocial,
  setTabIndex,
}: {
  uid: string;
  isSocial: boolean;
  setTabIndex?: (index: number) => void;
}) => {
  const router = useRouter();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [authError, setAuthError] = useState<string | null>(null);
  const searchParams = useSearchParams();
  const { refreshUserDoc } = useAuth();

  const { register, handleSubmit, setError, formState, watch } = useForm<{
    username: string;
  }>({
    defaultValues: {
      username: '',
    },
    mode: 'onChange',
    resolver: zodResolver(
      z.object({
        username: usernameSchema,
      })
    ),
  });

  const usernameValue = watch('username');

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('Username value:', usernameValue);
      console.log('Form errors:', formState.errors);
    }
  }, [usernameValue, formState.errors]);

  const errors = formState.errors;

  const submit: SubmitHandler<{ username: string }> = async (data) => {
    setIsSubmitting(true);
    setAuthError(null); // Clear any previous errors

    try {
      if (!uid && !AUTH.currentUser?.uid) {
        setAuthError('Authentication error. Please try signing up again.');
        logError('Cannot update username: uid is empty');
        setIsSubmitting(false);
        return;
      }

      const currentUsername = usernameValue || data.username;

      if (currentUsername.length < 4) {
        setError('username', {
          type: 'manual',
          message: 'Must be 4 or more characters',
        });
        setIsSubmitting(false);
        return;
      }

      const usernameExists = await getUserByUsernameSlug(currentUsername);

      if (usernameExists) {
        setAuthError('Username is already taken');
        setIsSubmitting(false);
        return;
      }

      try {
        await updateUsername(AUTH.currentUser?.uid ?? '', currentUsername);
        refreshUserDoc();

        if (
          searchParams?.get('step') &&
          searchParams?.get('phone_verified') === 'true'
        ) {
          const urlSearchParams = new URLSearchParams(window.location.search);
          urlSearchParams.set('phone_verified', 'true');
          urlSearchParams.set('step', '3');
          const newUrl = `${
            window.location.pathname
          }?${urlSearchParams.toString()}`;
          window.history.replaceState({}, '', newUrl);

          if (setTabIndex) {
            setTabIndex(3);
          }
        } else {
          if (isSocial) router.push('/dashboard/my-profile');
          else if (setTabIndex) {
            setTabIndex(3);
          }
        }
      } catch (error) {
        if (
          error instanceof FirebaseError &&
          error.code === 'auth/insufficient-permission'
        ) {
          if (isSocial) router.push('/dashboard/my-profile');
          else if (setTabIndex) {
            setTabIndex(3);
          }
        } else if (
          error instanceof Error &&
          error.message.includes('30 days')
        ) {
          setAuthError(error.message);
        } else {
          logError(error);
          throw error;
        }
      }
    } catch (error) {
      if (
        error instanceof FirebaseError &&
        error.code === 'auth/insufficient-permission'
      ) {
        if (isSocial) router.push('/dashboard/my-profile');
        else if (setTabIndex) {
          setTabIndex(3);
        }
      } else {
        console.log({ error });
        setAuthError('Error updating username. Please try again.');
        logError(error as Error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="mx-auto w-full max-w-[36rem]"
    >
      <motion.h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
        className="text-[2.4rem] font-semibold sm:text-[3rem]"
      >
        Create a Username
      </motion.h1>
      <motion.h2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
        className="mt-[0.4rem] text-[1.8rem] text-[#444444]"
      >
        This will be displayed publicly to other users.
      </motion.h2>

      {authError && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="mt-8 flex flex-col"
        >
          <span className="tracking-widest text-zinc-500">ERROR</span>

          <span className="text-[1.8rem] font-medium text-brand-secondary">
            {authError}
          </span>
        </motion.div>
      )}

      <div className="relative mt-[2.4rem] flex flex-col gap-[2rem] lg:mt-[3.2rem]">
        <form
          className="flex flex-col gap-[2rem]"
          onSubmit={handleSubmit(submit)}
        >
          <AuthFormInput
            label="Username"
            name="username"
            required
            placeholder="Choose a username"
            register={register('username', {
              onChange: (e) => {
                if (errors.username && e.target.value.length >= 4) {
                  setError('username', { message: '' }, { shouldFocus: false });
                }
              },
            })}
            errorMessage={errors.username?.message}
            autoComplete="off"
          />

          <div className="mt-5 w-full">
            <Button
              text="Submit"
              type="secondary"
              loading={isSubmitting}
              buttonType="submit"
              width="fluid"
              className="!text-[1.8rem]"
            />
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default UsernameForm;
