import { useState } from 'react';
import Button from '@ui/Button';
import AuthProvider from 'context/AuthContext';
import { FacebookBrandIcon, GoogleBrandIcon, AppleBrandIcon } from './icons';
import { motion } from 'framer-motion';
import Spinner from '@ui/Spinner';

const SocialLoginButtons = ({
  handleSocialClick: onSocialClick,
  providers,
}: {
  handleSocialClick: (s: AuthProvider) => Promise<void>;
  providers?: string[];
}) => {
  const [isLoading, setIsLoading] = useState<string | null>(null);
  const [clickedButton, setClickedButton] = useState<string | null>(null);

  const handleSocialClick = async (provider: AuthProvider) => {
    setClickedButton(provider);
    setIsLoading(provider);

    try {
      await onSocialClick(provider);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(null);
      setClickedButton(null);
    }
  };

  const buttonVariants = {
    initial: { scale: 1 },
    clicked: { scale: 0.95 },
    release: { scale: 1 },
  };

  return (
    <motion.div
      className="flex w-full gap-[0.4rem]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5 }}
    >
      {(!providers || providers.includes('facebook')) && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="w-full"
        >
          {isLoading === 'facebook' ? (
            <div className="flex h-[4.8rem] w-full items-center justify-center rounded-[1rem] border border-gray-300 bg-white">
              <div className="flex h-[2.5rem] w-[3rem] items-center justify-center">
                <Spinner className="h-10 w-10 text-black" />
              </div>
            </div>
          ) : (
            <motion.div
              variants={buttonVariants}
              initial="initial"
              animate={clickedButton === 'facebook' ? 'clicked' : 'initial'}
              whileTap="clicked"
              transition={{ duration: 0.1 }}
            >
              <Button
                text={''}
                leadingIcon={<FacebookBrandIcon />}
                type="sso"
                onClick={() => handleSocialClick('facebook')}
                width="fluid"
                ariaText="login with facebook"
                disabled={!!isLoading}
              />
            </motion.div>
          )}
        </motion.div>
      )}
      {(!providers || providers.includes('google')) && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7 }}
          className="w-full"
        >
          {isLoading === 'google' ? (
            <div className="flex h-[4.8rem] w-full items-center justify-center rounded-[1rem] border border-gray-300 bg-white">
              <div className="flex h-[2.5rem] w-[3rem] items-center justify-center">
                <Spinner className="h-10 w-10 text-black" />
              </div>
            </div>
          ) : (
            <motion.div
              variants={buttonVariants}
              initial="initial"
              animate={clickedButton === 'google' ? 'clicked' : 'initial'}
              whileTap="clicked"
              transition={{ duration: 0.1 }}
            >
              <Button
                text={''}
                leadingIcon={<GoogleBrandIcon />}
                type="sso"
                onClick={() => handleSocialClick('google')}
                width="fluid"
                ariaText="login with google"
                disabled={!!isLoading}
              />
            </motion.div>
          )}
        </motion.div>
      )}
      {(!providers || providers.includes('apple')) && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
          className="w-full sm:w-full"
        >
          {isLoading === 'apple' ? (
            <div className="flex h-[4.8rem] w-full items-center justify-center rounded-[1rem] border border-gray-300 bg-white">
              <div className="flex h-[2.5rem] w-[3rem] items-center justify-center">
                <Spinner className="h-10 w-10 text-black" />
              </div>
            </div>
          ) : (
            <motion.div
              variants={buttonVariants}
              initial="initial"
              animate={clickedButton === 'apple' ? 'clicked' : 'initial'}
              whileTap="clicked"
              transition={{ duration: 0.1 }}
            >
              <Button
                text={''}
                leadingIcon={<AppleBrandIcon />}
                type="sso"
                onClick={() => handleSocialClick('apple')}
                width="fluid"
                ariaText="login with apple"
                disabled={!!isLoading}
              />
            </motion.div>
          )}
        </motion.div>
      )}
    </motion.div>
  );
};

export default SocialLoginButtons;
