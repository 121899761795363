import Button from '@ui/Button';
import { motion, AnimatePresence } from 'framer-motion';
import { useState, useEffect } from 'react';

export default function WelcomeTab() {
  const facts = [
    'MXLocker is the largest powersports marketplace in the country.',
    'Find thousands of motorcycles, ATVs, and UTVs from trusted sellers.',
    'MXLocker connects buyers directly with powersports enthusiasts.',
    'Our platform features vehicles from all major manufacturers.',
    'MXLocker users save an average of 15% compared to dealership prices.',
    'Join thousands of riders who found their perfect ride on MXLocker.',
  ];

  const [currentFactIndex, setCurrentFactIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFactIndex((prevIndex) => (prevIndex + 1) % facts.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="mx-auto w-full rounded-2xl border border-zinc-100 bg-white px-6 py-10 shadow-sm sm:max-w-[60rem] sm:px-8 sm:py-12"
    >
      <div className="flex flex-col items-center text-center">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.1, duration: 0.5 }}
          className="mb-6 flex h-20 w-20 items-center justify-center rounded-full bg-brand-secondary"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-white"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
            <polyline points="22 4 12 14.01 9 11.01"></polyline>
          </svg>
        </motion.div>

        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="text-[3rem] font-semibold text-zinc-900"
        >
          Welcome to MXLocker!
        </motion.h1>

        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="mt-[1rem] max-w-[45rem] whitespace-normal text-[1.8rem] text-zinc-600"
        >
          Your account has been created successfully. You can verify your email
          address if you&apos;d like, but it&apos;s optional.
        </motion.h2>

        {/* FACTS SECTION IS COMMENTED OUT FOR NOW */}
        {/* <div className="relative mx-auto my-[3rem] min-h-[6rem] w-full max-w-[45rem] overflow-hidden rounded-[1rem] border border-zinc-200 bg-gradient-to-r from-zinc-50 to-zinc-100 p-4 shadow-md shadow-zinc-800/10">
          <AnimatePresence mode="wait">
            <motion.p
              key={currentFactIndex}
              initial={{
                opacity: 0,
                rotateX: -30,
                transformPerspective: 1000,
              }}
              animate={{
                opacity: 1,
                rotateX: 0,
                transformPerspective: 1000,
                transition: {
                  type: 'spring',
                  damping: 20,
                  stiffness: 100,
                  duration: 0.8,
                },
              }}
              exit={{
                opacity: 0,
                rotateX: 30,
                transformPerspective: 1000,
                transition: {
                  duration: 0.5,
                },
              }}
              className="relative z-10 whitespace-normal text-center text-[1.6rem] font-medium text-zinc-700 sm:text-[1.7rem]"
            >
              <span className="relative inline-block">
                {facts[currentFactIndex]}
              </span>
            </motion.p>
          </AnimatePresence>
        </div>

        <div className="mt-4 flex items-center justify-center space-x-3">
          {facts.map((_, index) => (
            <motion.button
              key={index}
              onClick={() => setCurrentFactIndex(index)}
              className={`h-2.5 rounded-full transition-all duration-300 ${
                index === currentFactIndex
                  ? 'w-6 bg-brand-primary'
                  : 'w-2.5 bg-zinc-200 hover:bg-zinc-300'
              }`}
              animate={{
                scale: index === currentFactIndex ? 1 : 0.9,
                opacity: index === currentFactIndex ? 1 : 0.7,
              }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.2 }}
            />
          ))}
        </div> */}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="mx-auto mt-[5rem] flex w-full max-w-[45rem] flex-col items-center justify-center gap-4 sm:flex-row sm:gap-6"
        >
          <Button
            type="secondary"
            className="w-full !text-[1.8rem] font-semibold"
            href={'/'}
          >
            Start Shopping
          </Button>

          <Button
            type="primary"
            className="w-full !text-[1.8rem] font-semibold"
            href={'/dashboard/my-profile'}
          >
            Edit Profile
          </Button>
        </motion.div>
      </div>
    </motion.div>
  );
}
