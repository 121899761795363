'use client';

import PhoneForm from '@c/auth/PhoneForm';
import FormLabel from '@c/forms/controls/FormLabel';
import { LoginCountryDropdown } from '@c/forms/CountryDropdown';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@ui/Button';
import { getUserByPhone } from '@util/firestore/users';
import { logError } from '@util/logError';
import AuthProvider, { useAuth } from 'context/AuthContext';
import { useToastContext } from 'context/ToastContext';
import { FirebaseError } from 'firebase/app';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import Link from 'next/link';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { motion } from 'framer-motion';
import { dialCountries } from 'features/contryDropdown/lib/countries';
import SocialLoginButtons from '@c/SocialLoginButton';
import { useSearchParams } from 'next/navigation';
import { UserAttribution } from 'models/user';

type PhoneSignupTabProps = {
  handleSocialClick: (s: AuthProvider) => Promise<void>;
  setSignupStep: (step: number) => void;
  setCreatedUserDoc: Dispatch<
    SetStateAction<{
      uid: string;
      name: string;
      isSocial: boolean;
      phone: string;
      smsConsent: boolean;
    }>
  >;
  returningUser?: boolean;
  attribution?: UserAttribution;
  globalSocialLinkError?: string | null;
};

export default function PhoneSignupTab({
  handleSocialClick,
  setCreatedUserDoc,
  setSignupStep,
  returningUser,
  attribution,
  globalSocialLinkError,
}: PhoneSignupTabProps) {
  const [countryCode, setCountryCode] = useState('US');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showErrorToast } = useToastContext();
  const [authError, setAuthError] = useState<FirebaseError | null>(null);
  const searchParams = useSearchParams();

  const [userInfo, setUserInfo] = useState({
    uid: '',
    name: '',
    isSocial: false,
    phone: '',
    smsConsent: false,
  });

  const { sendPhoneVerification } = useAuth();
  const [step, setStep] = useState<'signup' | 'verify'>('signup');
  const [verificationId, setVerificationId] = useState<string | null>(null);

  // this refine was AI generated. I like the validation tho
  const formSchema = z.object({
    phone: z
      .string()
      .min(4, 'Phone number is too short')
      .max(15, 'Phone number is too long')
      .refine((value) => {
        // Remove any non-digit characters
        const digitsOnly = value.replace(/\D/g, '');
        // Check if contains only digits and has reasonable length
        return /^\d{4,15}$/.test(digitsOnly);
      }, 'Please enter a valid phone number'),
  });

  type Form = z.infer<typeof formSchema>;

  const { setValue, handleSubmit, formState, watch } = useForm<Form>({
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
  });

  const errors = formState.errors;
  const form = watch();

  const formatPhoneNumber = (phoneNumber: string): string => {
    return phoneNumber.replace(/[^\d]/g, '');
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    const contryDialCode = dialCountries.find(
      (c) => c.code === countryCode
    )?.dial_code;

    const formattedPhone = formatPhoneNumber(form.phone);
    const fullNumber = `${contryDialCode}${formattedPhone}`;

    const parsed = parsePhoneNumber(fullNumber, countryCode as CountryCode);
    setAuthError(null);

    if (!parsed) {
      setIsSubmitting(false);

      // returning an error if the phone number refine fails to catch invalid phone numbers
      setAuthError(
        new FirebaseError('auth/invalid-phone-number', 'Invalid phone number')
      );
      return;
    }

    try {
      const userExists = await getUserByPhone(parsed.number);

      if (userExists) {
        showErrorToast(
          'This phone number is already in use. Please try another.'
        );

        setAuthError(
          new FirebaseError(
            'auth/phone-number-already-in-use',
            'Phone number already in use'
          )
        );

        setIsSubmitting(false);
        return;
      }

      try {
        const verificationId = await sendPhoneVerification(
          parsed.formatInternational()
        );

        setStep('verify');
        setVerificationId(verificationId);

        setUserInfo({
          uid: '',
          name: '',
          isSocial: false,
          phone: parsed.formatInternational(),
          smsConsent: true,
        });
      } catch (e) {
        showErrorToast((e as Error).message);
        logError(e);
        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
      console.log({ error });
    }
  };

  if (step === 'verify') {
    return (
      <PhoneForm
        attribution={attribution}
        countryCode={countryCode}
        setCountryCode={(code) => {
          setCountryCode(code);
        }}
        verificationId={verificationId ?? ''}
        phone={userInfo.phone}
        continueFn={() => {
          if (searchParams?.get('step')) {
            const urlSearchParams = new URLSearchParams(window.location.search);
            urlSearchParams.set('phone_verified', 'true');
            urlSearchParams.set('step', '2');
            const newUrl = `${
              window.location.pathname
            }?${urlSearchParams.toString()}`;
            window.history.replaceState({}, '', newUrl);

            setSignupStep(2);
          } else {
            setSignupStep(1);
          }
        }}
        setCreatedUserDoc={(userDoc) => {
          setUserInfo(userDoc);
          setCreatedUserDoc(userDoc);
        }}
      />
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full sm:max-w-[50rem]"
    >
      <motion.h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
        className="text-[3rem] font-semibold"
      >
        {returningUser ? "Let's complete your account" : 'Sign Up'}
      </motion.h1>
      <motion.h2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
        className="mt-[0.4rem] text-[1.8rem] text-[#444444]"
      >
        {returningUser
          ? 'Your account is almost complete. Please enter your phone number to continue.'
          : 'Join the largest online community of riders on the Powersports Marketplace.'}
      </motion.h2>

      {(authError || globalSocialLinkError) && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="mt-8 flex flex-col"
        >
          <span className="tracking-widest text-zinc-500">ERROR</span>

          <span className="text-[1.8rem] font-medium text-brand-secondary">
            {authError?.message || globalSocialLinkError}
          </span>
        </motion.div>
      )}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4 }}
        className="relative mt-[2.4rem] flex w-full flex-col gap-[2rem] lg:mt-[3.2rem]"
      >
        <form
          className="flex flex-col gap-[2rem]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormLabel required value="Phone" className="!text-[1.8rem]">
            <div className="flex flex-col gap-y-4">
              <LoginCountryDropdown
                value={form.phone}
                autoComplete="tel-national"
                onChange={(e) => setValue('phone', e)}
                onCountryChange={setCountryCode}
                countryCode={countryCode}
              />

              {errors.phone && (
                <span className="text-[1.8rem] text-brand-red">
                  {errors.phone.message}
                </span>
              )}
            </div>
          </FormLabel>

          <Button
            text={returningUser ? 'Continue' : 'Create Account'}
            type="secondary"
            loading={isSubmitting}
            buttonType="submit"
            className="!h-[5.5rem] !text-[1.8rem]"
          />
        </form>

        {!returningUser && (
          <>
            <div className="flex h-[2.4rem] justify-center">
              <p className="text-[1.8rem] text-[#444444]">or sign up with</p>
            </div>
            <SocialLoginButtons handleSocialClick={handleSocialClick} />
          </>
        )}
      </motion.div>

      {!returningUser && (
        <motion.div
          className="mt-[4.8rem] flex flex-col gap-y-4 lg:mt-[6rem]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          <div className="flex w-full">
            <p className="text-[1.8rem] text-[#444444]">
              Already have an account?&nbsp;
              <Link
                href="/login"
                className="font-semibold text-brand-secondary"
              >
                Log in
              </Link>
            </p>
          </div>

          <div className="flex w-full">
            <p className="text-[1.6rem] text-[#444444]">
              By creating an account, you agree to our&nbsp;
              <Link
                href="/terms-and-conditions"
                target="_blank"
                className="font-semibold text-brand-secondary"
              >
                Terms of Service
              </Link>
              &nbsp;and understood the&nbsp;
              <Link
                href="/privacy-policy"
                target="_blank"
                className="font-semibold text-brand-secondary"
              >
                Privacy Policy
              </Link>
            </p>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
}
