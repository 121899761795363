import { CheckmarkIcon } from '..';
interface Props {
  checked?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  label?: string;
  className?: string;
}
const CheckmarkIconButton = ({
  checked,
  disabled = false,
  onClick,
  label,
  className,
}: Props) => {
  return (
    <div
      className={`flex w-fit cursor-pointer items-center ${className}`}
      onClick={onClick}
    >
      <button
        disabled={disabled}
        className={`flex min-h-[2.5rem] min-w-[2.5rem] items-center justify-center rounded-[.5rem] transition-all duration-200 ease-in-out focus:border-none sm:min-h-[1.6rem] sm:min-w-[1.6rem] ${
          checked
            ? 'scale-[1.05] bg-brand-secondary'
            : 'scale-100 border border-brand-dark-gray'
        } ${className}`}
        type="button"
      >
        <div
          className={`transition-opacity duration-200 ${
            checked ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <CheckmarkIcon height={8} width={12} variant="white" />
        </div>
      </button>

      {label && (
        <div className="ml-[1rem] select-none text-[1.8rem] font-medium !text-black sm:text-[1.6rem]">
          {label}
        </div>
      )}
    </div>
  );
};

export default CheckmarkIconButton;
