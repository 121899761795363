import { cva } from 'class-variance-authority';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Eye, EyeOff } from 'lucide-react';
import { useState } from 'react';

interface AuthFormInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  required?: boolean;
  label: string;
  errorMessage?: string;
  register?: UseFormRegisterReturn<any>;
  autoComplete?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function AuthFormInput({
  className,
  required,
  label,
  errorMessage,
  register,
  autoComplete,
  onChange,
  type,
  ...props
}: AuthFormInputProps) {
  const [showPassword, setShowPassword] = useState(false);
  const isPassword = type === 'password';

  const styles = cva('block mb-[0.8rem] items-center text-black', {
    variants: {
      required: {
        true: 'after:content-["*"] after:ml-1 after:text-red-500',
      },
      flex: {
        true: 'flex items-center justify-between',
        default: 'block',
      },
    },
    defaultVariants: {
      required: false,
      flex: 'default',
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className={`flex w-full flex-col items-start gap-y-4 border-none text-input ${className}`}
    >
      <span className={`${styles({ required })} text-[1.8rem]`}>{label}</span>

      <div className="relative w-full border-b border-zinc-200">
        <input
          className="w-full border-none bg-inherit py-[5px] text-[2.1rem] text-zinc-700 outline-none placeholder:text-zinc-400"
          placeholder="Enter your email"
          type={isPassword ? (showPassword ? 'text' : 'password') : type}
          {...register}
          {...props}
          onChange={onChange}
        />
        {isPassword && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer"
            aria-label={showPassword ? 'Hide password' : 'Show password'}
          >
            {showPassword ? (
              <EyeOff className="mr-4 h-10 w-10 text-zinc-500" />
            ) : (
              <Eye className="mr-4 h-10 w-10 text-zinc-500" />
            )}
          </button>
        )}
      </div>

      {!!errorMessage && (
        <span className={`w-full max-w-[40rem] text-[1.8rem] text-brand-red`}>
          {errorMessage}
        </span>
      )}
    </div>
  );
}
