import { CheckmarkIcon } from '@c/icons';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { logEvent } from '@util/analytics';
import {
  createUserDocument,
  getUserByEmail,
  getValidUserDocument,
} from '@util/firestore/users';
import AuthProvider, { useAuth } from 'context/AuthContext';
import { FirebaseError } from 'firebase/app';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState, useRef } from 'react';
import PhoneSignupTab from './tabs/PhoneSignupTab';
import EmailInfoSignupTab from './tabs/UserInfoSignupTab';
import UsernameForm from '../UsernameForm';
import WelcomeTab from './tabs/WelcomeTab';
import { AUTH } from '@util/firebase';

export const ActiveCheckIcon = ({ active }: { active: boolean }) => {
  return (
    <div
      className={`flex h-[1.6rem] w-[1.6rem] items-center justify-center rounded-full p-[0.3rem] ${
        active ? 'bg-[#1E7A1E]' : 'bg-[#7F7F7F]'
      }`}
    >
      <CheckmarkIcon variant="white" height={12} width={12} />
    </div>
  );
};

const SignupForm = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const tabIndexRef = useRef(0);
  const [userInfo, setUserInfo] = useState({
    uid: '',
    name: '',
    isSocial: false,
    phone: '',
    smsConsent: false,
  });
  const [countryCode, setCountryCode] = useState('');
  const searchParams = useSearchParams();
  const router = useRouter();
  const [globalSocialLinkError, setGlobalSocialLinkError] = useState<
    string | null
  >(null);

  const { loginSocial, attribution, getUserPhoneNumber } = useAuth();

  const handleSocialClick = async (s: AuthProvider) => {
    const res = await loginSocial(s);

    if (res instanceof FirebaseError) {
      console.log('error', res);
    } else if (res && 'user' in res && res.user?.uid) {
      try {
        // if this succeeds and returns a user, user already exists
        const userDoc = getValidUserDocument(
          res.user.uid,
          res.user.email ?? '',
          {
            name: res.user.displayName ?? 'Anonymous',
            opted_out: false,
          },
          attribution
        );

        const userWithSameEmail = await getUserByEmail(res.user.email ?? '');
        const capitalizedProvider = s.charAt(0).toUpperCase() + s.slice(1);

        if (userWithSameEmail) {
          setGlobalSocialLinkError(
            `${capitalizedProvider} email is already in use.`
          );
          return;
        }

        if (userDoc || AUTH.currentUser) {
          const userPhoneNumber = await getUserPhoneNumber();

          // checking if the sign in user (or auth user) has a phone number
          if (!userPhoneNumber) {
            router.push('/signup?step=0');
            return;
          } else {
            router.push('/signup?step=1');
            return;
          }
        }
      } catch (e) {
        console.log('No user doc found, creating one now');
      }

      const docData = getValidUserDocument(
        res.user.uid,
        res.user.email ?? '',
        {
          name: res.user.displayName ?? 'Anonymous',
          opted_out: false,
        },
        attribution
      );

      logEvent('sign_up', { method: s });

      const createdUserDoc = await createUserDocument(docData, s);

      setUserInfo({
        uid: createdUserDoc.uid,
        name: createdUserDoc.name,
        isSocial: true,
        phone: '',
        smsConsent: false,
      });

      const userPhoneNumber = await getUserPhoneNumber();

      // checking if the sign in user (or auth user) has a phone number
      if (!userPhoneNumber) {
        router.push('/signup?step=0');
        return;
      } else {
        router.push('/signup?step=1');
        return;
      }
    } else {
      console.log('error', res);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && !countryCode) {
      setCountryCode(localStorage.getItem('mxlocker_country_code') || 'US');
    }
  }, [countryCode]);

  const paramsTab = searchParams?.get('step');

  useEffect(() => {
    tabIndexRef.current = tabIndex;

    if (paramsTab) {
      if (!AUTH.currentUser) {
        router.push('/signup');
        return;
      }

      setTabIndex(parseInt(paramsTab));
    }
  }, [tabIndex, paramsTab]);

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <div className="flex w-full items-start justify-center px-[2.4rem] pb-[3.2rem] sm:mt-[2.4rem] sm:p-0">
      {/* form */}
      {/* inputs and buttons */}
      <TabGroup selectedIndex={tabIndex} onChange={setTabIndex}>
        <TabList className="hidden">
          <Tab></Tab>
          <Tab></Tab>
          <Tab></Tab>
          <Tab></Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PhoneSignupTab
              handleSocialClick={handleSocialClick}
              setSignupStep={handleTabChange}
              setCreatedUserDoc={setUserInfo}
              returningUser={paramsTab === '0'}
              attribution={attribution ?? undefined}
              globalSocialLinkError={globalSocialLinkError}
            />
          </TabPanel>
          <TabPanel>
            <EmailInfoSignupTab
              createdUserDoc={userInfo}
              setTabIndex={handleTabChange}
            />
          </TabPanel>
          <TabPanel>
            <UsernameForm
              uid={userInfo.uid}
              isSocial={userInfo.isSocial}
              setTabIndex={handleTabChange}
            />
          </TabPanel>
          <TabPanel>
            <WelcomeTab />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export default SignupForm;
