'use client';

import AuthFormInput from '@c/forms/components/AuthFormInput';
import { AppleBrandIcon } from '@c/icons';
import { FacebookBrandIcon, GoogleBrandIcon } from '@c/icons';
import Checkbox from '@ui/Checkbox';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@ui/Button';
import {
  getUserByEmail,
  getUserDocument,
  getValidUserDocument,
  updateUserDoc,
} from '@util/firestore/users';
import { logError } from '@util/logError';
import AuthProvider, { useAuth } from 'context/AuthContext';
import { useToastContext } from 'context/ToastContext';
import { FirebaseError } from 'firebase/app';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { linkWithCredential } from 'firebase/auth';

type EmailInfoSignupTabProps = {
  createdUserDoc: {
    uid: string;
    name: string;
    isSocial: boolean;
    phone: string;
    smsConsent: boolean;
  };
  setTabIndex: (index: number) => void;
};

export default function EmailInfoSignupTab({
  createdUserDoc,
  setTabIndex,
}: EmailInfoSignupTabProps) {
  const [authError, setAuthError] = useState<string | null>(null);
  const { showSuccessToast } = useToastContext();
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const {
    linkWithCredentialSocial,
    sendEmailVerification,
    linkWithCredential,
  } = useAuth();

  const formSchema = z.object({
    email: z.string().email('Invalid email address'),
    optOut: z.boolean().optional(),
  });

  type Form = z.infer<typeof formSchema>;

  const { register, handleSubmit, formState, watch, setValue } = useForm<Form>({
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
  });

  const onSocialClick = async (provider: string) => {
    try {
      const res = await linkWithCredentialSocial(provider as AuthProvider);

      if (res instanceof FirebaseError) {
        setAuthError(res.message);
        return;
      }

      if (res) {
        await updateUserDoc(res.user.uid, {
          email: res.user.email ?? '',
          opted_out: watch('optOut') ?? false,
        });

        showSuccessToast('Account linked successfully');
        setTabIndex(2);
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        const capitalizedProvider =
          provider.charAt(0).toUpperCase() + provider.slice(1);

        if (error.message === 'auth/credential-already-in-use') {
          setAuthError(`${capitalizedProvider} email already in use.`);
          return;
        }

        if (error.message === 'auth/popup-closed-by-user') {
          setAuthError('Popup closed by the user. Please try again.');
          return;
        }

        setAuthError(error.message);
      } else {
        setAuthError('An error occurred while linking with social provider');
        logError(error);
      }
    }
  };

  const onSendEmail = async (data: Form) => {
    setIsSendingEmail(true);

    try {
      try {
        const existentUser = await getUserByEmail(data.email);

        if (existentUser) {
          setAuthError('Email already in use');
          setIsSendingEmail(false);
          return;
        }

        if (createdUserDoc?.uid) {
          await updateUserDoc(createdUserDoc.uid, {
            email: data.email,
            opted_out: !!data.optOut,
            from_web: true,
          });

          await linkWithCredential(data.email);
          await sendEmailVerification();
        } else {
          throw new Error('User ID is missing');
        }

        showSuccessToast('Email updated successfully');
        setTabIndex(2);
      } catch (error) {
        if (
          error instanceof FirebaseError &&
          error.code === 'auth/insufficient-permission'
        ) {
          showSuccessToast('Email updated successfully');
          setTabIndex(2);
        } else {
          logError(error);
          throw error;
        }
      }
    } catch (e) {
      if (
        e instanceof FirebaseError &&
        e.code === 'auth/insufficient-permission'
      ) {
        showSuccessToast('Email updated successfully');
        setTabIndex(2);
      } else {
        console.log('Error updating email:', e);
        setAuthError('An error occurred');
      }
    } finally {
      setIsSendingEmail(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSendEmail)}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full sm:max-w-[50rem]"
      >
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="text-[3rem] font-semibold"
        >
          What&apos;s your email?
        </motion.h1>
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="mt-[0.4rem] text-[1.8rem] text-[#444444]"
        >
          Used for sign-in, account updates, and optional marketing emails.
        </motion.h2>

        {authError && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="mt-8 flex flex-col"
          >
            <span className="tracking-widest text-zinc-500">ERROR</span>

            <span className="text-[1.8rem] font-medium text-brand-secondary">
              {authError}
            </span>
          </motion.div>
        )}

        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="mt-16 text-[1.8rem] text-[#444444]"
        >
          <AuthFormInput
            label="Email"
            name="email"
            required
            type="email"
            placeholder="Your best email"
            register={register('email')}
            errorMessage={formState.errors.email?.message}
          />
        </motion.h2>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="mt-16 flex w-full text-[1.8rem]"
        >
          <Checkbox
            label="I do not wish to receive marketing communications about MX Locker products & services."
            selected={watch('optOut')}
            onChange={() => setValue('optOut', !watch('optOut'))}
          />
        </motion.div>

        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="mt-16 text-[1.8rem] text-[#444444]"
        >
          <Button
            text={'Continue'}
            type="secondary"
            loading={isSendingEmail}
            buttonType="submit"
            className="!h-[5.5rem] w-full !text-[1.8rem]"
          />
        </motion.h2>

        <motion.div
          className="mt-8 flex h-[2.4rem] justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          <p className="text-[1.8rem] text-[#444444]">
            or bring your email from
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="mt-8  flex gap-[0.4rem]"
        >
          <Button
            text={''}
            leadingIcon={<FacebookBrandIcon />}
            type="sso"
            onClick={() => onSocialClick('facebook')}
            width="fluid"
            ariaText="Login with facebook"
          />
          <Button
            text={''}
            leadingIcon={<GoogleBrandIcon />}
            type="sso"
            onClick={() => onSocialClick('google')}
            width="fluid"
            ariaText="Login with google"
          />
          <Button
            text={''}
            leadingIcon={<AppleBrandIcon />}
            type="sso"
            onClick={() => onSocialClick('apple')}
            width="fluid"
            ariaText="Login with apple"
          />
        </motion.div>
      </motion.div>
    </form>
  );
}
